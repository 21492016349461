import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bench Press 2-2-2-2-2 to a 2RM`}</p>
    <p>{`Strict CTB Pullups 2-2-2-2-2 to a 2RM (weighted if possible)`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`21-HSPU’s`}</p>
    <p>{`15-Hang Power Cleans (135/95)`}</p>
    <p>{`9-Box Jump Overs (24/20)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Wednesday, July 4th, we will have 1 class at 9:00am that is free
for everyone so bring a friend!  Open gym will follow from 10am-1pm but
the 9:00am class is the only class for the day.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      